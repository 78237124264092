<template>
  <b-card
    no-body
    class="p-3"
  >
    <b-row >
      <b-col
        cols="12"
      >
        <validation-observer ref="formRef">
          <b-form @submit.prevent>
            <b-row>
              <!-- Cashbank Account Field -->
              <b-col cols="12" md="3">
                <b-form-group
                  :label="$t(`apps.transactions.purchase.payment.singular.payFrom`)"
                  label-for="cashbank-account"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="cashbank"
                    rules="required"
                  >
                    <v-select
                      v-if="company.plan_id > 2"
                      id="cash-bank-account"
                      ref="firstFocusSelectInput"
                      class="select-size-sm"
                      v-model="form.cashbankId"
                      :options="LOV.cashbankCodeAccounts"
                      :reduce="field => field.id"
                      label="label"
                      :state="errors.length > 0 ? false:null"
                      :disabled="actions.isPreview"
                    />
                    <v-select
                      v-else
                      id="cash-bank-account"
                      ref="firstFocusSelectInput"
                      class="select-size-sm"
                      v-model="form.accountId"
                      :options="LOV.cashBankAccounts"
                      :reduce="field => field.id"
                      label="label"
                      :state="errors.length > 0 ? false:null"
                      :disabled="actions.isPreview"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- Date Field -->
              <b-col cols="12" md="3">
                <b-form-group
                  :label="$t('globalSingular.date')"
                  label-for="date"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="date"
                    rules="required"
                  >
                    <flat-pickr
                      id="date"
                      :config="dateFormat"
                      v-model="form.date"
                      :state="errors.length > 0 ? false:null"
                      :disabled="actions.isPreview"
                      class="form-control form-control-sm"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- PIC Office Field -->
              <b-col cols="12" md="3">
                <b-form-group
                :label="$t('apps.transactions.purchase.request.singular.Office')"
                label-for="requestor"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="requestor"
                    rules="required"
                  >
                    <v-select
                      inputId="requestor"
                      ref="firstFocusSelectInput"
                      v-model="form.officeId"
                      :options="LOV.officeId"
                      :reduce="field => field.id"
                      label="label"
                      :state="errors.length > 0 ? false:null"
                      :disabled="actions.isPreview"
                      class="select-size-sm"
                      :placeholder="!actions.isPreview ? $t('apps.transactions.purchase.request.singular.Office') : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Invoice Field -->
              <b-col cols="12" md="3">
                <b-form-group
                  :label="$t('globalSingular.code')"
                  label-for="code"
                >
                  <!-- <validation-provider
                    #default="{ errors }"
                    name="code"
                    rules="required"
                  > -->
                    <b-form-input
                      id="code"
                      size="sm"
                      v-model="form.code"
                      :placeholder="$t('globalSingular.auto')"
                      :disabled="actions.isPreview"
                    />
                    <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                  <!-- </validation-provider> -->
                </b-form-group>
              </b-col>

              <!-- Transaction No Field -->
              <b-col cols="12" md="3">
                <b-form-group
                  :label="$t(`globalSingular.vendor`)"
                  label-for="vendor"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="vendor"
                    rules="required"
                  >
                    <v-select
                      class="select-size-sm"
                      id="vendor"
                      v-model="form.vendorId"
                      :options="LOV.vendors"
                      :reduce="field => field.id"
                      label="label"
                      :state="errors.length > 0 ? false:null"
                      :disabled="actions.isPreview"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="3" class="d-flex align-items-center">
                <b-button
                  class="mb-sm-1 mb-md-0"
                  :variant="actions.isPreview ? 'outline-secondary' : 'primary'"
                  :disabled="!form.vendorId"
                  @click="() => getSelectedVendors(form.contactId)"
                >
                  <!-- <feather-icon
                    icon="ArrowLeftIcon"
                  /> -->
                  {{ $t('globalActions.generate') }}
                  <!-- Generate -->
                </b-button>
              </b-col>

              <!-- Tags Field -->
              <b-col cols="12" md="3">
                <b-form-group
                  :label="$t('globalSingular.tags')"
                  label-for="tags"
                >
                  <v-select
                    v-model="form.tags"
                    id="tags"
                    label="name"
                    multiple
                    :options="LOV.tags"
                    :reduce="field => field.id"
                    :searchable="!actions.isPreview"
                    :selectable="option => !actions.isPreview"
                  />
                </b-form-group>
              </b-col>
            </b-row>


            <b-overlay
              :show="showLoading"
              variant="dark"
              :opacity="0.25"
              rounded="sm"
            >
              <b-table
                id="refFormTable"
                ref="refFormTable"
                responsive
                no-provider-paging
                no-provider-filtering
                :fields="tableColumns"
                :items="detailInvoicePayment"
                primary-key="id"
                show-empty
                class="position-relative mt-2"
              >

                <!-- Custom Header Column -->
                <template #head(actions)>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    class="mx-auto"
                  />
                </template>
                <!-- Styling width -->
                <!-- Column: Account -->
                <!-- <template #cell(invoiceId)="{ index }">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="account"
                      rules="required"
                      :vid="`detail-${index}`"
                    >
                      <v-select
                        id="account-type"
                        v-model="form.details[index].accountId"
                        :options="LOV.accounts"
                        :reduce="field => field.id"
                        label="label"
                        :state="errors.length > 0 ? false:null"
                        :disabled="actions.isPreview"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </template> -->

                <!-- Column: Invoice No -->
                <template #cell(rowNumber)="{ index }">
                  <b-form-group>
                    <b-form-input size="sm" v-model="form.details[index].rowNumber" disabled />
                    <!-- <span>{{form.details[index].rowNumber}}</span> -->
                  </b-form-group>
                </template>

                <!-- Column: Invoice No -->
                <template #cell(invoiceNo)="{ index }">
                  <b-form-group>
                    <b-form-input size="sm" v-model="form.details[index].invoiceNo" disabled />
                  </b-form-group>
                </template>

                <!-- Column: Invoice Amount -->
                <template #cell(billAmount)="{ index }">
                  <b-form-group>
                    <cleave
                      :key="index"
                      v-model.number="form.details[index].billAmount"
                      class="form-control text-right form-control-sm"
                      :options="numeric"
                      placeholder="10,000"
                      disabled
                    />
                  </b-form-group>
                </template>

                <!-- Column: Outstanding Amount -->
                <template #cell(outstandingAmount)="{ index }">
                  <b-form-group>
                    <cleave
                      :key="index"
                      v-model.number="form.details[index].outstandingAmount"
                      class="form-control text-right form-control-sm"
                      :options="numeric"
                      placeholder="10,000"
                      disabled
                    />
                  </b-form-group>
                </template>

                <!-- Column: PaymentAmount -->
                <template #cell(amount)="{ index }">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="amount"
                      :rules="`required|${!actions.isPreview ? `max_value:${form.details[index].outstandingAmount}` : ''}`"
                      :vid="`detail-${index}`"
                    >
                      <cleave
                        :key="index"
                        v-model.number="form.details[index].amount"
                        :state="errors.length > 0 ? false:null"
                        class="form-control text-right form-control-sm"
                        :options="numeric"
                        placeholder="10,000"
                        :disabled="actions.isPreview"
                        @blur="sumAmount"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="{ index }">
                  <b-button
                    variant="flat-danger"
                    class="btn-icon invoice-edit-input"
                    :disabled="form.details.length <= 1"
                    @click="removeRow(form.details[index].rowNumber)"
                  >
                    <feather-icon icon="MinusIcon" />
                  </b-button>
                </template>

                <!-- Costum Footer -->
                <template v-slot:custom-foot="{}">
                  <b-tr>
                    <b-td class="pl-0" colspan="2">
                      &nbsp;
                    </b-td>
                    <b-td class="text-right">
                      <div class="h5 mb-0 font-weight-bolder">{{ $t('globalSingular.totalPaymentAmount') }}</div>
                    </b-td>
                    <b-td class="text-right">
                      <div class="h5 mb-0 font-weight-bolder">{{ formatCurrency(form.amount) }}</div>
                    </b-td>
                    <b-td>&nbsp;</b-td>
                  </b-tr>
                  <b-tr v-for="(field, index) in deductionPayment" :key="index" >
                    <b-td class="border-0 pl-0 " colspan="1">
                      <b-form-group label="Deduction to Invoice">
                        <validation-provider
                          #default="{ errors }"
                          name="account"
                          rules="required"
                          :vid="`detail-${field.rowNumber - 1}`"
                        >
                          <v-select
                            class="select-size-sm"
                            id="invoice-deduction-id"
                            v-model="form.details[field.rowNumber - 1].invoiceId"
                            :options="LOV.outstandingInvoices"
                            :reduce="field => field.id"
                            label="code"
                            :disabled="actions.isPreview"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-td>
                    <b-td class="border-0 align-top pl-5" colspan="2">
                      <b-form-group label="Deduction Account">
                        <validation-provider
                          #default="{ errors }"
                          name="account"
                          rules="required"
                          :vid="`detail-${field.rowNumber - 1}`"
                        >
                          <v-select
                            class="select-size-sm"
                            id="deduction-type"
                            v-model="form.details[field.rowNumber - 1].deductionAccountId"
                            :options="LOV.accounts"
                            :reduce="field => field.id"
                            label="label"
                            :disabled="actions.isPreview"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-td>
                    <b-td class="border-0 align-top text-right">
                      <b-form-group label="Deduction Amount">
                        <validation-provider
                          #default="{ errors }"
                          name="amount"
                          :rules="`required|max_value:${form.details[index].amount}`"
                          :vid="`detail-${field.rowNumber - 1}`"
                        >
                          <cleave
                            :key="field.rowNumber - 1"
                            v-model.number="form.details[field.rowNumber - 1].deductionAmount"
                            :state="errors.length > 0 ? false:null"
                            class="form-control text-right text-danger form-control-sm"
                            :options="numeric"
                            placeholder="10,000"
                            :disabled="actions.isPreview"
                            @blur="sumAmount"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-td>
                    <b-td class="border-0">
                      <b-button
                        v-if="!actions.isPreview"
                        variant="flat-danger"
                        class="btn-icon invoice-edit-input"
                        @click="removeRow(field.rowNumber)"
                      >
                        <feather-icon icon="MinusIcon" />
                      </b-button>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="border-0 pl-0 " colspan="2">
                      &nbsp;
                    </b-td>
                    <b-td class="border-0 text-right">
                      <!-- <div class="h5 mb-0 font-weight-bolder">{{ $t('globalSingular.totalPaymentAmount') }}</div> -->
                      &nbsp;
                    </b-td>
                    <b-td class="border-0 text-right">
                      <b-button
                        v-if="!actions.isPreview"
                        variant="flat-primary"
                        class="btn-icon invoice-edit-input"
                        @click="addDeduction"
                      >
                        <feather-icon icon="PlusIcon" />
                        {{ $t('apps.transactions.purchase.payment.actions.addDeduction') }}
                      </b-button>
                    </b-td>
                    <b-td class="border-0">&nbsp;</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td class="pl-0 border-0" colspan="2">
                      &nbsp;
                    </b-td>
                    <b-td class="text-right border-0">
                      <div class="h4 mb-0 font-weight-bolder">{{ $t('globalSingular.moneyReceived') }}</div>
                    </b-td>
                    <b-td class="text-right border-0">
                      <div class="h4 mb-0 font-weight-bolder">{{ totalMoneyWillReceive }}</div>
                    </b-td>
                    <b-td class="border-0">&nbsp;</b-td>
                  </b-tr>
                </template>
              </b-table>
            </b-overlay>


            <b-row>
              <b-col cols="12" md="12">
                <b-form-group
                  :label="$t('globalSingular.notes')"
                  label-for="notes"
                >
                  <b-form-textarea
                    id="notes"
                    size="sm"
                    v-model="form.description"
                    :placeholder="!actions.isPreview ? $t('globalSingular.description') : null"
                    :disabled="actions.isPreview"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="5" md="6">
                <b-form-group
                  :label="$t('apps.transactionsCashbank.singular.image')"
                  label-for="images"
                >
                  <b-form-file
                    multiple
                    :file-name-formatter="formatNames"
                    :disabled="actions.isPreview"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <div class="mt-3 d-flex flex-sm-column flex-md-row justify-content-between">
              <b-button
                class="mb-sm-1 mb-md-0"
                :variant="actions.isPreview ? 'outline-secondary' : 'outline-primary'"
                :to="{ name: 'apps-ap-oustandingPaymentHome-list' }"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                />
                {{ $t('globalActions.backToList') }}
              </b-button>

              <b-button
                v-if="actions.isPreview"
                class=" mb-sm-1 mb-md-0"
                variant="outline-danger"
                @click="handleDelete"
              >
                <feather-icon
                  icon="Trash2Icon"
                />
                {{ $t('globalActions.delete') }}
              </b-button>

              <b-button
                v-if="!actions.isPreview"
                variant="primary"
                type="submit"
                @click="handleSubmit"
              >
                <feather-icon
                  icon="SaveIcon"
                />
                {{ actions.isEditMode ? $t('globalActions.update') : $t('globalActions.create') }}
              </b-button>

              <!-- <b-button
                v-else
                variant="outline-primary"
                @click="changeToEdit"
              >
                <feather-icon
                  icon="EditIcon"
                />
                {{ $t('globalActions.changeToEdit') }}
              </b-button> -->
            </div>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </b-card>
</template>


<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BTr,
  BTd,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BButton,
  BOverlay
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import { ref, onMounted, computed } from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, maxValue } from '@validations'
import { formatCurrency, formatDatepicker } from '@/utils/formatter'
import VuexStore from '@/store'
import { BFormFile } from 'bootstrap-vue'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useHttp from '@/comp-functions/useHttp'


import useFormResource from '@/comp-functions/useFormResource'
import usePushTags from '@/comp-functions/utils/usePushTags'
import useLocalization from '@/comp-functions/utils/useLocalization'
import storestate from '@/store/index'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BTr,
    BTd,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    Cleave,
    BFormFile,
    BOverlay
  },
  setup () {
    const {
      paramsId,
      // routeParams,
      formRef,
      firstFocusSelectInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store
    } = useFormResource({ url: 'transaction/purchase/payment', localeContextPath: 'apps.transactions.purchase.payment.singular.payment', redirectPathName: 'apps-transactions-purchase-payment' })

    const company = computed(() => {
      return VuexStore.state.user.currentCompany
    })

    const { $t } = useLocalization()

    const LOV = ref({
      vendors: [],
      cashbankAccounts: [],
      cashbankCodeAccounts: [],
      accounts: [],
      tags: [],
      outstandingInvoices: [],
      officeId: []
    })

    const { toast, $post } = useHttp()
    const showLoading = ref(false)

    const getVendors = async () => {
      LOV.value.vendors = await get({ url: 'value/outstanding-purchase-vouchers'})
    }

    const getCashbankAccount = async () => {
      LOV.value.cashbankAccounts = await get({ url: 'master/accounts/child?account_subtype_code=CASHBANK'})
    }

    const getCashbankCode = async () => {
      let companies = storestate.state.user.companies
      let currentCompany = storestate.state.user.currentCompany

      const currentOffice = companies.filter(company => company.company_id == currentCompany.id)

      if(storestate.state.user.isNational === true){
        LOV.value.cashbankCodeAccounts = await get({ url: 'master/cashbank'})
      }else {
        LOV.value.cashbankCodeAccounts = await get({ url: 'value/mapping-cashbank-per-users'})
        LOV.value.cashbankCodeAccounts = LOV.value.cashbankCodeAccounts.filter(company => company.id == currentOffice[0].office_id)
      }
    }

    const getAccounts = async () => {
      LOV.value.accounts = await get({ url: 'master/accounts/child'})
    }
    const getContacts = async () => {
      LOV.value.contacts = await get({ url: 'value/contactPayment/supplier'})
    }
    const getTags = async () => {
      LOV.value.tags = await get({ url: 'value/tag'})
    }

    const getOfficeId = async () => {
      let companies = storestate.state.user.companies
      let currentCompany = storestate.state.user.currentCompany

      const currentOffice = companies.filter(company => company.company_id == currentCompany.id)

      //condition if user national show all office show office equal user office
      if(storestate.state.user.isNational === true){
        LOV.value.officeId = await get({ url: 'master/offices'})
      }else {
        LOV.value.officeId = await get({ url: 'master/offices'})
        LOV.value.officeId = LOV.value.officeId.filter(company => company.id == currentOffice[0].office_id)
      }
    }

    const tableColumns = ref([])

    const form = ref({
      accountId: '',
      cashbankId: '',
      contactId: '',
      vendorId: '',
      officeId: '',
      images: [],
      totalImages: 0,
      code: '',
      paymentTypeCode: '',
      date: formatDatepicker(Date.now()),
      tags: [],
      description: '',
      amount: 0,
      deductionAmount: 0,
      details: [
        {
          rowNumber: null,
          invoiceId: null,
          invoiceNo: null,
          voucherId: null,
          billAmount: null,
          outstandingAmount: null,
          amount: null,
          deductionAccountId: null,
          deductionAmount: null
        }
      ]
    })

    const getSelectedVendors = async () => {
      showLoading.value = true
      form.value.contactId = form.value.vendorId
      let data = []

      await get({
        url: `transaction/purchase/posting-ap-payment-outstanding?contact_id=${form.value.vendorId}`,
      })
        .then((result) => { data = result; form.value.details = []})

      await Promise.all(data.map((invoice, index) => {
        form.value.details.push({
          rowNumber: index++,
          invoiceId: invoice.id_invoice,
          invoiceNo: invoice.no_inv,
          // voucherId: invoice.form_payment_id,
          billAmount: parseFloat(invoice.invoice_amount || 0),
          outstandingAmount: parseFloat(invoice.outstanding_amount || 0),
          amount: parseFloat(invoice.outstanding_amount || 0),
          deductionAccountId: null,
          deductionAmount: 0
        })
      }))

      showLoading.value = false
    }

    const dateFormat = ref({ dateFormat: 'd/m/Y', allowInput: true })

    const numeric = ref({
      numeral: true,
      numeralThousandsGroupStyle: 'thousand'
    })

    /**
     * @remark sum of amount and deduction amount when these value change
    */
    const sumAmount = () => {
      form.value.amount = form.value.details.reduce((accumulator, {amount}) => accumulator + amount, 0)
      form.value.deductionAmount = form.value.details.reduce((accumulator, {deductionAmount}) => accumulator + deductionAmount, 0)
    }

    // will get data to show the preview
    const getDataPreview = async () => {
      const data = await get({ url: `transaction/purchase/payment/r`})
      form.value.accountId = data.account_id
      if (company.value.plan_id > 2) {
        form.value.cashbankId = data.cashbank_id
      }
      form.value.accountId = data.account_id
      form.value.contactId = data.contact_id
      form.value.code = data.code
      form.value.officeId = data.office_id
      form.value.paymentTypeCode = data.payment_type_code
      form.value.date = data.date
      form.value.dueDate = data.due_date
      form.value.tags = data.tags.map(tag => parseInt(tag))
      form.value.description = data.description
      form.value.amount = parseFloat(data.amount)
      form.value.deductionAmount = parseFloat(data.deduction_amount)
      form.value.details = data.details.map(field => {
        const deductionAmount = parseFloat(field.deduction_amount)
        return {
          rowNumber: field.row_number,
          invoiceId: field.invoice_id,
          invoiceNo: !deductionAmount ? field.invoice.code : '',
          billAmount: !deductionAmount ? parseFloat(field.invoice.sum_of_bill_amount) : 0,
          outstandingAmount: !deductionAmount ? parseFloat(field.invoice.outstanding_amount) : 0,
          amount: !deductionAmount ? parseFloat(field.amount) : 0,
          deductionAccountId: field.deduction_account_id,
          deductionAmount
        }
      })
    }

    // add deduction amount
    const addDeduction = () => {
      form.value.details.push({
        rowNumber: form.value.details.length + 1,
        invoiceId: null,
        invoiceNo: '',
        billAmount: 0,
        outstandingAmount: 0,
        amount: 0,
        deductionAccountId: null,
        deductionAmount: null
      })
    }

    const totalMoneyWillReceive = computed(() => {
      const total = form.value.amount - form.value.deductionAmount
      return formatCurrency(total)
    })

    const detailInvoicePayment = computed(() => form.value.details.filter(field => field.billAmount > 0))
    const deductionPayment = computed(() => form.value.details.filter(field => field.billAmount === 0))

    // Remove row deduction / payment list
    const removeRow =  (rowNumber) => {
      const index = form.value.details.findIndex(field => field.rowNumber === rowNumber)
      form.value.details.splice(index, 1)
      sumAmount()
    }

    onMounted(() => {
      getVendors()
      getCashbankAccount()
      getAccounts()
      getTags()
      getOfficeId()
      getContacts()
      if (paramsId) {
        actions.value.isPreview = true
        getDataPreview()
      }
      if (company.value.plan_id > 2) {
        getCashbankCode()
      }
      tableColumns.value = [
        // { key: 'rowNumber', label: '#', width: '10%', thClass: 'bg-transparent pl-0', tdClass: 'align-top pl-0' },
        { key: 'invoiceNo', label: $t('apps.transactions.purchase.payment.singular.invoiceNo'), width: '23%', thClass: 'bg-transparent pl-0 width-25-per', tdClass: 'align-top pl-0' },
        { key: 'billAmount', label: $t('apps.transactions.purchase.payment.singular.invoiceAmount'), width: '24%', thClass: 'bg-transparent text-right width-25-per', tdClass: 'align-top text-right'},
        { key: 'outstandingAmount', label: $t('apps.transactions.purchase.payment.singular.outstandingAmount'), width: '24%', thClass: 'bg-transparent text-right width-25-per', tdClass: 'align-top text-right'},
        { key: 'amount', label: $t('apps.transactions.purchase.payment.singular.paymentAmount'), width: '30%', thClass: 'bg-transparent text-right width-25-per', tdClass: 'align-top text-right'},
        { key: 'actions', width: '12%', thClass: 'bg-transparent text-right width-10-per  pr-0', tdClass: 'align-top text-right pr-0'}
      ]
    })

    const { pushTagsToAPI } = usePushTags()
    const pushTags = async newTag => {
      const id = await pushTagsToAPI(newTag)
      form.value.tags[form.value.tags.findIndex(val => val === newTag.id)] = id
    }

    return {
      company,
      getSelectedVendors,
      pushTags,
      required,
      maxValue,
      LOV,
      tableColumns,
      detailInvoicePayment,
      deductionPayment,
      form,
      addDeduction,
      removeRow,
      totalMoneyWillReceive,
      dateFormat,
      formatCurrency,
      sumAmount,
      $post,
      toast,
      numeric,
      paramsId,
      formRef,
      firstFocusSelectInput,
      get,
      actions,
      changeToEdit,
      show,
      destroy,
      store,
      showLoading,
      BFormFile
    }
  },


  methods: {
    formatNames (files) {
      this.form.images = [];
      for (let i = 0; i < files.length; i++) {
        this.form.images.push(files[i]);
      }

      for (let u = 0; u < files.length; u++) {
        const reader = new FileReader()
        console.log(reader)
        reader.readAsDataURL(this.form.images[u])
      }

      this.form.totalImages = files.length;

      if (files.length === 1) {
        return files[0].name
      }

      return `${ files.length } files selected`
    },


    async handleDelete () {
      await this.destroy(this.$swal)
    },


    async handleSubmit () {
      const $swal = this.$swal
      await $swal({
        title: `Create new Payment ? After created you still can delete or edit later.`,
        icon: 'question',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-flat-warning'
        }
      }).then(async () => {
        const forms = new FormData()
        for (let u = 0; u < this.form.images.length; u++) {
          forms.append('avatar'+[u], this.form.images[u])
        }
        forms.append('data',JSON.stringify(this.form))

        const callbackSuccess = () => {
          this.toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Success',
              icon: 'BellIcon',
              variant: 'success',
              text: `Successfully Created Payment!.`
            }
          })
          router.replace({ name: `apps-ap-oustandingPaymentHome-list` })
        }

        await this.$post({
          data:forms,
          url: `/transaction/purchase/payment`
        }).then((res) => callbackSuccess(res))

      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
